import React, { useRef } from 'react';
import { FormControl, TextField, Box } from '@mui/material';
import { Controller, FieldValues, Path } from 'react-hook-form';
import { theme } from 'themes/theme';
import { FormOtpInputProps } from './common/formTypes';

export default function FormOtpInput<FormValueT extends FieldValues>(
  props: FormOtpInputProps<FormValueT>
) {
  const { control, otpLength, name } = props;
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    onFieldChange: (value: string) => void
  ) => {
    const newValue = e.target.value.replace(/\D/g, '');
    if (newValue.length <= 1) {
      onFieldChange(newValue);
    }

    // Move to the next input field
    if (newValue && index < otpLength - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  return (
    <Box display="flex" gap={1}>
      {Array.from({ length: otpLength }, (_, index) => (
        <FormControl key={index}>
          <Controller
            name={`${name}[${index}]` as Path<FormValueT>}
            control={control}
            rules={{
              required: 'Required',
              validate: value => value.length === 1 || 'Only one digit allowed'
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={e => handleChange(e, index, onChange)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Backspace') {
                    if (e.currentTarget.value) {
                      onChange('');
                    } else if (index > 0) {
                      inputRefs.current[index - 1]?.focus();
                      const prevValue =
                        inputRefs.current[index - 1]?.value || '';
                      onChange(prevValue.slice(0, -1));
                    }
                  }
                }}
                inputRef={el => (inputRefs.current[index] = el)}
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: 'center', fontSize: '1.5rem' }
                }}
                error={!!error}
                helperText={error?.message}
                sx={{
                  width: '3.2rem',
                  '& .MuiOutlinedInput-root': {
                    height: '3.2rem',
                    borderRadius: '0.5rem',
                    border: '1px solid #EAECF0',
                    background: '#FEFFFF',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent'
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: `${theme.palette.common.btnColor}`,
                      boxShadow: `${theme.palette.common.boxShadow}`
                    }
                  }
                }}
              />
            )}
          />
        </FormControl>
      ))}
    </Box>
  );
}
