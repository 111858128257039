import React, { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import { ReactComponent as X } from 'assets/svg/x.svg';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import WorkflowViewerCommentCard from './commentCard';

type WorkflowViewerCommentsProps = {
  displayComments: () => void;
};

const WorkflowViewerCommentsLayout = ({
  displayComments
}: WorkflowViewerCommentsProps) => {
  const [viewReplies, setViewReplies] = useState<{ [key: number]: boolean }>(
    {}
  );

  const handleViewReply = (id: number) => {
    setViewReplies(prev => {
      if (prev[id]) {
        return { ...prev, [id]: false };
      } else {
        return { ...prev, [id]: true };
      }
    });
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        border: '.0625rem solid #F5F6F7',
        borderRadius: '.75rem',
        height: 'inherit',
        width: {
          md: '10rem',
          lg: '15rem',
          xl: '20rem'
        }
      }}
    >
      <Box
        padding="0.75rem 1rem"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          borderBottom: '.064rem solid #F5F6F7',
          background: '#ffffff',
          borderRadius: '.75rem .75rem 0rem 0rem'
        }}
      >
        <Typography variant="bodyMediumSemibold" color="#667085">
          Review ATC received
        </Typography>

        <Box
          padding="0.5rem"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: ' #F5F6F7',
            borderRadius: '6.25rem',
            cursor: 'pointer'
          }}
          onClick={displayComments}
        >
          <SvgWrapper
            height="1rem"
            width="1rem"
            icon={X}
            styleOverrides={{ fill: '#475467' }}
          />
        </Box>
      </Box>

      <Stack
        direction="column"
        width="inherit"
        height="100%"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          overflowY: 'auto',
          pb: '1rem'
        }}
      >
        {[1, 2, 3].map((_, index) => (
          <React.Fragment key={index}>
            <WorkflowViewerCommentCard
              viewReply={!!viewReplies[index]}
              toggleViewReply={() => handleViewReply(index)}
            />
          </React.Fragment>
        ))}
      </Stack>

      <Box
        padding=".75rem"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          borderTop: '.064rem solid #F5F6F7',
          background: '#ffffff',
          borderRadius: '0rem 0rem .75rem .75rem'
        }}
      >
        <Button
          color="primary"
          transparent
          size="md"
          text="Add new Comment"
          width="inherit"
          icon={Plus}
          iconPosition="start"
        />
      </Box>
    </Stack>
  );
};

export default WorkflowViewerCommentsLayout;
