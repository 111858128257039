export const passwordStrengthValue = (value: number) => {
  switch (true) {
    case Number(value) < 3:
      return 'Weak.';
    case Number(value) > 3:
      return 'Strong.';
    case Number(value) === 3:
      return 'Medium.';
    default:
      return 'Weak.';
  }
};

export const passwordStrength = (value: string) => {
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasNumber = /[0-9]/.test(value);
  const hasSymbol = /[!@?&#$%^]/.test(value);
  let validConditions = 0;
  const numberOfMustBeValidConditions = 4;
  const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol];
  conditions.forEach(condition => (condition ? validConditions++ : null));
  const isValid = validConditions >= numberOfMustBeValidConditions;
  return { isValid, validConditions };
};
