import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from 'components/button';
import { OnboardingContext } from '../context/onboardingContext';
import { theme } from 'themes/theme';
import { useAppSelector } from 'services/hook';
import OtpVerificationForm from 'components/form/formOtpInput';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { otpStepFormValidator } from '../validators/onboardingValidators';
import {
  useGetVerificationEmailQuery,
  useVerifyEmailMutation
} from 'services/api/userApi';
import ShowInfo from 'components/info/showInfo';

type FormValues = {
  otp: string[];
};

interface MyError {
  data: {
    [key: string]: string[];
  };
  message?: string;
}

const EmailVerification = () => {
  const [counter, setCounter] = useState(120);
  const [isDisabled, setIsDisabled] = useState(true);
  const [shouldFetch, setShouldFetch] = useState(false);

  const { goToStep } = useContext(OnboardingContext);
  const { user } = useAppSelector(state => state.register);
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const {
    isSuccess,
    isError,
    isLoading: resendEmailLoading
  } = useGetVerificationEmailQuery({}, { skip: !shouldFetch });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      otp: ['', '', '', '', '', '']
    },
    resolver: yupResolver(
      otpStepFormValidator
    ) as unknown as Resolver<FormValues>
  });

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => {
        setCounter(prev => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setIsDisabled(false);
    }
  }, [counter]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const handleResend = () => {
    setShouldFetch(true);
    if (isSuccess) {
      ShowInfo({
        message: 'Email sent',
        subText: '',
        type: 'success',
        componentType: 'toast',
        hideProgressBar: false
      });
      setCounter(60);
      setIsDisabled(true);
    }
    if (isError) {
      ShowInfo({
        message: 'Email failed to send. Please try again',
        subText: '',
        type: 'error',
        componentType: 'toast',
        hideProgressBar: false
      });
    }
  };

  const onSubmit: SubmitHandler<FormValues> = data => {
    verifyEmail({
      verification_id: data.otp.join('')
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Email successfully verified!',
          subText: '',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        goToStep(2);
      })
      .catch((err: MyError) => {
        if (err) {
          if (
            typeof err.data === 'object' &&
            Object.values(err.data).length > 0
          ) {
            ShowInfo({
              message: `${Object.values(err.data)[0]}`,
              subText: '',
              type: 'error',
              componentType: 'toast',
              hideProgressBar: false
            });
          } else if (typeof err.data === 'string') {
            ShowInfo({
              message: `${err.data}`,
              subText: '',
              type: 'error',
              componentType: 'toast',
              hideProgressBar: false
            });
          } else {
            ShowInfo({
              message: 'Verification unsuccessful!',
              subText: '',
              type: 'error',
              componentType: 'toast',
              hideProgressBar: false
            });
          }
        } else {
          ShowInfo({
            message: 'An error occurred during verification. Please try again.',
            subText: (err as MyError)?.message,
            type: 'error',
            componentType: 'toast',
            hideProgressBar: false
          });
        }
      });
  };

  return (
    <Box
      sx={{
        width: '34rem',
        margin: '0 auto',
        padding: '2.5rem',
        pt: '2rem'
      }}
    >
      <Box sx={{ width: { lg: '29.125rem' } }}>
        <Box
          width="inherit"
          sx={{
            mb: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <Typography
            variant="h6Bold"
            sx={{
              color: `${theme.palette.common.heading}`,
              mb: '0.5rem'
            }}
          >
            Verify your email
          </Typography>
          <Typography
            variant="bodyMediumRegular"
            sx={{
              color: `${theme.palette.common.labelColor}`
            }}
          >
            We sent an email to <strong>{user.email}</strong>. Check your inbox
            or spam folder and enter the OTP to proceed.
          </Typography>
        </Box>

        <Box
          width="inherit"
          sx={{
            mb: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <OtpVerificationForm
            control={control}
            otpLength={6}
            label={undefined}
            name={'otp'}
          />
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box width="inherit" sx={{ mb: '1rem' }}>
            <Button
              transparent
              color="primary"
              size="md"
              submit
              text="Verify Email"
              width="100%"
              disabled={!isValid || !isDirty}
              loadingIcon={isLoading || isSubmitting}
              styleOverrides={{ marginBottom: '0.25rem', border: '0px' }}
            />
          </Box>

          <Box width="inherit" sx={{ mb: '0.75rem' }}>
            <Button
              color="primary"
              size="md"
              onClick={handleResend}
              disabled={isDisabled}
              text={`Resend Email ${isDisabled ? `${formatTime(counter)}` : ''}`}
              width="100%"
              styleOverrides={{ marginBottom: '0rem' }}
              loadingIcon={resendEmailLoading}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default EmailVerification;
