import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

interface CustomSkeletonProps<TableDataT> {
  rowCount: number;
  columns: ColumnDef<TableDataT>[];
}

function CustomSkeleton<TableDataT>({
  rowCount,
  columns
}: CustomSkeletonProps<TableDataT>) {
  return (
    <>
      {Array.from({ length: rowCount }).map((_, rowIndex) => (
        <Box
          key={rowIndex}
          sx={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}
        >
          <Skeleton
            variant="rectangular"
            width={60}
            animation="wave"
            height={40}
            sx={{
              marginRight: '0.5rem',
              borderRadius: '4px',
              bgcolor: '#F2F4F7'
            }}
          />

          {columns.map((column, colIndex) => {
            const columnWidth = column.size ? `${column.size}%` : '40%';

            return (
              <Skeleton
                key={colIndex}
                variant="rectangular"
                animation="wave"
                width={columnWidth}
                height={40}
                sx={{
                  marginRight: colIndex === columns.length - 1 ? '0' : '1%',
                  borderRadius: '4px',
                  bgcolor: '#F2F4F7'
                }}
              />
            );
          })}
        </Box>
      ))}
    </>
  );
}

export { CustomSkeleton };
