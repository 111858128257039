import React, { useState } from 'react';
import { Box, Popover, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { Button } from 'components/button';
import { getDateRange } from 'utilities/helperFunc/getDateRange';
import CreateReportModal from '../createReportModal';

export interface DateRangeType {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string;
  granularity: 'daily' | 'monthly' | 'yearly';
}

interface ChartNavProps {
  header?: string;
  caption?: string;
  showButton?: boolean;
  setDateRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
}

const dateFilters = [
  'Last 7 days',
  'Last 14 days',
  'This Month',
  'Last Month',
  'This Year',
  'Last Year',
  'Last 3 Months',
  'Last 6 Months'
];

export const Nav: React.FC<ChartNavProps> = ({
  header,
  caption,
  showButton = false,
  setDateRange
}) => {
  const [createReportModalOpen, setCreateReportModalOpen] = useState(false);
  const [anchorFilterEl, setAnchorFilterEl] =
    useState<HTMLButtonElement | null>(null);
  const [filterPopup, setFilterPopup] = useState(false);
  const [dateText, setDateText] = useState('This Month');

  const handleDateSelection = (period: string) => {
    setDateText(period);
    const { start, end, granularity } = getDateRange(period);
    setDateRange({
      startDate: start,
      endDate: end,
      granularity: granularity,
      key: 'selectedPeriod'
    });
    setAnchorFilterEl(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
        px: 1,
        py: 1
      }}
    >
      <Stack direction="column" gap={0.5}>
        <Typography variant="h7Semibold" color="#475467">
          {header}
        </Typography>
        {caption && (
          <Typography variant="bodyMediumMedium" color="#98A2B3">
            {caption}
          </Typography>
        )}
      </Stack>

      <Box display="flex" gap="1rem">
        <Button
          onClick={e => {
            setFilterPopup(true);
            setAnchorFilterEl(e.currentTarget);
          }}
          color={'grey'}
          text={dateText}
          transparent
          icon={ChevronDown}
          iconPosition="end"
          size="md"
        />

        <Popover
          id={filterPopup ? 'filter-popover' : undefined}
          open={Boolean(anchorFilterEl)}
          anchorEl={anchorFilterEl}
          onClose={() => setAnchorFilterEl(null)}
          sx={{
            '& .MuiPopover-paper': {
              borderBottomRightRadius: '.75rem',
              borderBottomLeftRadius: '.75rem',
              border: '.0625rem solid #EAECF0',
              width: '7.5rem'
            }
          }}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Stack direction="column" width="inherit">
            {dateFilters.map(item => (
              <Box
                onClick={() => handleDateSelection(item)}
                key={item}
                sx={{
                  width: 'inherit',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  padding: '0.5rem 0.75rem',
                  borderBottom: '0.1rem solid #F5F6F7',
                  cursor: 'pointer'
                }}
              >
                <Typography
                  variant={'bodyMediumSemibold'}
                  component="div"
                  color="#475467"
                >
                  {item}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Popover>

        {showButton && (
          <Button
            text="Create Report"
            color="primary"
            size="md"
            transparent
            onClick={() => setCreateReportModalOpen(true)}
          />
        )}
      </Box>

      <CreateReportModal
        open={createReportModalOpen}
        onClose={() => setCreateReportModalOpen(false)}
        setCreateReportModalOpen={setCreateReportModalOpen}
      />
    </Box>
  );
};
