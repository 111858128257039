import React, { useState } from 'react';
import {
  Box,
  Tab as MUITab,
  Tabs as MUITabs,
  Paper,
  Typography
} from '@mui/material';
import Loading from 'components/loader/loading';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'services/hook';
import ProductReceiptDetails from './details/productReceiptDetailsPage';
import ProductReceiptPickup from './pickup/productReceiptPickup';
import ProductReceiptInfoTabLayout from './productReceiptInfoTabLayout';
import ProductReceiptWorkflows from './workflows/productReceiptWorkflows';
import { useGetProductReceiptQuery } from 'services/api/productReceiptApi';
import { Button } from 'components/button';
import EndorsementModal from './endorsementModal';
import { OrganizationType } from 'services/enums/organization';
import { useGetPermissionsQuery } from 'services/api/permissionsApi';
import { PermissionType } from 'services/enums/permissions';
import ShowInfo from 'components/info/showInfo';
import ProductReceiptAnalytics from './analytics/productReceiptAnalytics';

const tabList = ['details', 'workflow', 'analytics', 'pickup'];
const ProductReceiptInfoPage = () => {
  const { organizationType, organizationConfig } = useAppSelector(
    state => state.auth
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { productReceiptId, tab } = useParams();
  const currentTab = tabList.indexOf(tab ?? 'details');

  const { data } = useGetProductReceiptQuery({
    productReceiptId: productReceiptId ?? ''
  });
  const [endorsementWorkflowModalOpen, setEndorsementWorkflowModalOpen] =
    useState<boolean>(false);
  const { data: permissions } = useGetPermissionsQuery({});

  const handleChange = (newTabIndex: number) => {
    const lastSlashIndex = location.pathname.lastIndexOf('/');
    const basePath = location.pathname.substring(0, lastSlashIndex);
    navigate(`${basePath}/${tabList[newTabIndex]}`);
  };

  const isActiveTab = (tab: string) => {
    return tab === tabList[currentTab];
  };

  return (
    <Box sx={{ height: '100%', position: 'relative' }}>
      {isActiveTab('workflow') && (
        <Box
          sx={{
            width: 'fit-content',
            position: 'absolute',
            zIndex: 1,
            right: '1rem',
            top: '1rem'
          }}
        >
          {(!organizationConfig.requirePrcEndorsementWorkflow ||
            data?.endorsement_workflow?.completed) &&
            (data?.endorsements.length === 0 ||
              (organizationType !== OrganizationType.MERCHANT &&
                (data?.endorsements?.length ?? 0) < 2)) && (
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  color="primary"
                  size="md"
                  iconPosition="start"
                  text={
                    organizationType === OrganizationType.MERCHANT
                      ? 'Issue to Customer'
                      : 'Endorse to Customer'
                  }
                  onClick={() => {
                    if (
                      !permissions ||
                      !permissions.includes(
                        PermissionType.PRODUCT_RECEIPT_COMPLETE_ENDORSEMENT
                      )
                    ) {
                      ShowInfo({
                        message: 'Cannot complete endorsement',
                        subText: 'You do not have permissions to do this!',
                        type: 'error',
                        componentType: 'toast',
                        hideProgressBar: false
                      });
                      return;
                    }
                    setEndorsementWorkflowModalOpen(true);
                  }}
                />
              </Box>
            )}
        </Box>
      )}
      <Paper
        elevation={0}
        sx={{
          padding: '1.25rem',
          borderRadius: '0.75rem',
          backgroundColor: '#FFF',
          position: 'relative',
          height: '100%',
          border: '.0625rem solid #F5F6F7'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            background: '#fff'
          }}
        >
          <MUITabs
            orientation="horizontal"
            value={Math.max(0, currentTab)}
            onChange={(e, value) => handleChange(value)}
            sx={{
              borderBottom: 1,
              borderColor: '#F5F6F7',
              marginBottom: '1.75rem',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">Details</Typography>
              }
            />
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">Workflow</Typography>
              }
            />
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">Analytics</Typography>
              }
            />
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">
                  Product pickup
                </Typography>
              }
            />
          </MUITabs>
          {data ? (
            <ProductReceiptInfoTabLayout>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('details')}
                id="simple-tabpanel-details"
                aria-labelledby="simple-tab-details"
                sx={{ width: '100%' }}
              >
                <ProductReceiptDetails productReceipt={data} />
              </Box>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('workflow')}
                id="simple-tabpanel-workflow"
                aria-labelledby="simple-tab-workflow"
                sx={{ width: '100%' }}
              >
                <ProductReceiptWorkflows productReceipt={data} />
              </Box>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('analytics')}
                sx={{ width: '100%' }}
              >
                <ProductReceiptAnalytics productReceipt={data} />
              </Box>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('pickup')}
                id="simple-tabpanel-pickup"
                aria-labelledby="simple-tab-pickup"
                sx={{ width: '100%' }}
              >
                <ProductReceiptPickup productReceipt={data} />
              </Box>
            </ProductReceiptInfoTabLayout>
          ) : (
            <Loading loading={true} loadingText="Loading Product Receipt" />
          )}
        </Box>
      </Paper>

      <EndorsementModal
        open={endorsementWorkflowModalOpen}
        onClose={() => {
          setEndorsementWorkflowModalOpen(!endorsementWorkflowModalOpen);
        }}
        productReceipt={data}
      />
    </Box>
  );
};

export default ProductReceiptInfoPage;
