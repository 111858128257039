import React from 'react';
import { ReactComponent as Empty } from 'assets/custom-svg/empty-notif.svg';
import { Stack, Typography, Box } from '@mui/material';
import { SvgWrapper } from 'components/svg';

const NoNotifications = () => {
  return (
    <Stack
      direction="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        marginBottom: {
          lg: '10rem',
          xl: '15rem'
        }
      }}
    >
      <Box style={{ position: 'absolute' }}>
        <SvgWrapper
          icon={Empty}
          width="100%"
          height="100%"
          styleOverrides={{ fill: 'none' }}
        />
      </Box>
      <Stack
        direction="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={1}
        mt="15rem"
      >
        <Typography variant="h7Semibold" color="#475467">
          No New Notifications
        </Typography>

        <Box width="16rem" textAlign="center" mb=".5rem">
          <Typography variant="bodyMediumMedium" color="#98A2B3">
            We will notify you when you have important updates
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default NoNotifications;
