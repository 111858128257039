import React, { useState } from 'react';
import { Box, Typography, Stack, Avatar } from '@mui/material';
import ellipse from 'assets/svg/ellipse.svg';
import profile from 'assets/svg/profile.svg';
import { ReactComponent as ChevronDown } from 'assets/svg/arrowDown.svg';
import { ReactComponent as ChevronUp } from 'assets/svg/chevron-up.svg';
import { ReactComponent as VerticalMenu } from 'assets/svg/vertical-menu.svg';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import WorkflowViewerReplyCommentCard from './replyCommentCard';

type WorkflowViewerCommentCardProps = {
  viewReply: boolean;
  toggleViewReply: () => void;
};

const WorkflowViewerCommentCard = ({
  viewReply,
  toggleViewReply
}: WorkflowViewerCommentCardProps) => {
  const [viewComment, setViewComment] = useState<boolean>(false);

  return (
    <>
      <Stack
        direction="column"
        padding="0rem 0.75rem"
        alignItems="center"
        width="inherit"
        sx={{
          border: '.064rem solid #F5F6F7',
          borderTop: 'none',
          background: '#FFF'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginY: '0.75rem',
            width: '100%'
          }}
        >
          <Stack direction="column" width="inherit">
            <Stack
              direction="row"
              width="100%"
              gap=".5rem"
              alignItems="center"
              justifyContent="space-between"
            >
              <Avatar src={profile} style={{ width: '2rem', height: '2rem' }} />
              <Stack
                direction="row"
                width="auto"
                gap=".5rem"
                alignItems="center"
              >
                <Button
                  color="grey"
                  transparent
                  size="sm"
                  text="Reply"
                  styleOverrides={{
                    border: 'none',
                    background: '#FCFCFC'
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <SvgWrapper
                    icon={VerticalMenu}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{ fill: '#667085' }}
                  />
                </Box>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="column">
                <Typography variant="bodyMediumSemibold" color="#475467">
                  Jane Smith
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '.25rem'
                  }}
                >
                  <Typography variant="captionXSmall" color="#98A2B3">
                    4 minutes ago
                  </Typography>
                  <Box
                    component="img"
                    src={ellipse}
                    sx={{
                      color: '#D0D5DD',
                      width: '0.25rem',
                      height: '0.25rem'
                    }}
                  />
                </Box>
              </Stack>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => setViewComment(!viewComment)}
              >
                {viewComment ? (
                  <SvgWrapper
                    icon={ChevronDown}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{ stroke: '#667085', fill: 'none' }}
                  />
                ) : (
                  <SvgWrapper
                    icon={ChevronUp}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{ stroke: '#667085', fill: 'none' }}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        </Box>

        {viewComment && (
          <Stack direction="column" width="auto" gap=".5rem">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '.25rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#98A2B3">
                #1
              </Typography>
              <Box
                component="img"
                src={ellipse}
                sx={{ color: '#D0D5DD', width: '0.25rem', height: '0.25rem' }}
              />
              <Typography variant="bodyMediumMedium" color="#98A2B3">
                Review ATC received
              </Typography>
            </Box>

            <Typography variant="bodyMediumMedium" color="#667085">
              Purchase order document is incorrect. Check errors to see if the
              issues are from us or the merchant....{' '}
              <span style={{ color: '#6597F9' }}>@evelyn</span>
            </Typography>
          </Stack>
        )}

        {viewComment && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              cursor: 'pointer',
              gap: '0.5rem',
              margin: '.75rem'
            }}
            onClick={toggleViewReply}
          >
            <Typography variant="bodyMediumSemibold" color="#667085">
              10 Replies
            </Typography>
            {viewReply ? (
              <SvgWrapper
                icon={ChevronUp}
                width="1rem"
                height="1rem"
                styleOverrides={{ stroke: '#667085', fill: 'none' }}
              />
            ) : (
              <SvgWrapper
                icon={ChevronDown}
                width="1rem"
                height="1rem"
                styleOverrides={{ stroke: '#667085', fill: 'none' }}
              />
            )}
          </Box>
        )}
      </Stack>
      {viewReply && <WorkflowViewerReplyCommentCard />}
    </>
  );
};

export default WorkflowViewerCommentCard;
