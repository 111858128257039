import React from 'react';
import { Box, Typography, Stack, Avatar } from '@mui/material';
import profile from 'assets/svg/profile.svg';
import { ReactComponent as VerticalMenu } from 'assets/svg/vertical-menu.svg';
import { SvgWrapper } from 'components/svg';

const WorkflowViewerReplyCommentCard = () => {
  return (
    <Stack
      direction="column"
      padding="0rem 1.5rem"
      alignItems="center"
      sx={{
        background: '#F8FBFE',
        border: '.064rem solid #F5F6F7',
        borderTop: 'none',
        borderBottom: 'none'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginY: '0.75rem',
          width: '100%'
        }}
      >
        <Stack direction="row" width="auto" gap=".25rem" alignItems="center">
          <Avatar src={profile} style={{ width: '2rem', height: '2rem' }} />
          <Stack direction="column">
            <Typography variant="bodyMediumSemibold" color="#475467">
              Jane Smith
            </Typography>
            <Typography variant="captionXSmall" color="#98A2B3">
              4 minutes ago
            </Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
        >
          <SvgWrapper
            icon={VerticalMenu}
            width="1rem"
            height="1rem"
            styleOverrides={{ fill: '#667085' }}
          />
        </Box>
      </Box>

      <Typography variant="bodyMediumMedium" color="#667085">
        <span style={{ color: '#6597F9' }}>@evelyn</span> Purchase order
        document is incorrect. Check errors
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          cursor: 'pointer',
          gap: '0.5rem',
          margin: '.75rem'
        }}
      >
        <Typography variant="bodyMediumSemibold" color="#667085">
          Reply
        </Typography>
      </Box>
    </Stack>
  );
};

export default WorkflowViewerReplyCommentCard;
