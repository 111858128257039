import React from 'react';
import { Box } from '@mui/material';

import { WorkflowApiData } from 'services/types/workflow';
import { WorkflowTargetObject } from 'services/enums/workflows';
import WorkflowViewerStep from './workflowViewerStep';

type WorkflowsViewerStepsGroupProps = {
  workflow: WorkflowApiData;
  workflowTargetObject: WorkflowTargetObject;
  workflowTargetObjectId: string;
  invalidateTag: string;
  invalidateTagId: string | number;
  displayComments: (stepId: string) => void;
  selectedStepId: string | null;
};

const WorkflowsViewerStepsGroup = ({
  workflow,
  workflowTargetObject,
  workflowTargetObjectId,
  invalidateTag,
  invalidateTagId,
  displayComments,
  selectedStepId
}: WorkflowsViewerStepsGroupProps) => {
  const { steps, workflow_id } = workflow;
  return (
    <Box
      sx={{
        height: '90%',
        width: '100%'
      }}
    >
      {Object.values(steps).map((step, index) => {
        return (
          <React.Fragment key={index}>
            <WorkflowViewerStep
              workflowId={workflow_id}
              title={step.step_title}
              description={step.step_description}
              stepOrder={step.step_order}
              isActionable={step.is_actionable}
              stepOutcome={step.step_outcome}
              stepReApproved={step.step_re_approved}
              isLast={index === Object.values(steps).length - 1}
              workflowTargetObject={workflowTargetObject}
              workflowTargetObjectId={workflowTargetObjectId}
              invalidateTag={invalidateTag}
              invalidateTagId={invalidateTagId}
              actionedBy={step.actioned_by}
              actionedOn={step.actioned_on}
              ownedByTeam={step.owned_by_team}
              ownedByRole={step.owned_by_role}
              displayComments={() => displayComments(step.step_title)}
              isSelected={selectedStepId === step.step_title}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default WorkflowsViewerStepsGroup;
