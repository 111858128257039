export const getDateRange = (period: string) => {
  const endDate = new Date();
  const startDate = new Date();
  let granularity: 'daily' | 'monthly' | 'yearly' = 'daily';

  if (period.endsWith('7 days')) {
    startDate.setDate(endDate.getDate() - 7);
  } else if (period.endsWith('14 days')) {
    startDate.setDate(endDate.getDate() - 14);
  } else if (period.endsWith('30 days')) {
    startDate.setDate(endDate.getDate() - 30);
  } else if (period.endsWith('12 months')) {
    startDate.setFullYear(endDate.getFullYear() - 1);
  } else if (period === 'Last 3 Months') {
    startDate.setMonth(endDate.getMonth() - 3);
  } else if (period === 'Last 6 Months') {
    startDate.setMonth(endDate.getMonth() - 6);
  } else if (period === 'Last Year') {
    startDate.setFullYear(endDate.getFullYear() - 1);
    startDate.setMonth(0);
    startDate.setDate(1);
    endDate.setMonth(0);
    endDate.setDate(0);
    granularity = 'monthly';
  } else if (period === 'This Month') {
    startDate.setDate(1);
  } else if (period === 'Last Month') {
    startDate.setMonth(endDate.getMonth() - 1);
    startDate.setDate(1);
    endDate.setDate(0);
  } else if (period === 'This Year') {
    startDate.setMonth(0);
    startDate.setDate(1);
    granularity = 'monthly';
  }

  return {
    start: startDate,
    end: endDate,
    granularity: granularity
  };
};
