import React, { useMemo, useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  Checkbox,
  Radio
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ReactComponent as Send } from 'assets/svg/send.svg';
import { ReactComponent as User } from 'assets/svg/user.svg';
import { ReactComponent as Users } from 'assets/svg/users.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/x.svg';
import { Button } from 'components/button';
import {
  FormAutocomplete,
  FormDateInput,
  FormInput,
  FormRadio,
  FormSelect
} from 'components/form';
import { RadioChecked, RadioUnChecked } from 'components/form/formSelect';
import ShowInfo from 'components/info/showInfo';
import { SvgWrapper } from 'components/svg';
import { createReportValidator } from './validators/createReportValidator';
import { useCreateReportMutation } from 'services/api/analyticsApi';
import { useGetDiscoverableProfilesQuery } from 'services/api/supplyChainApi';
import { formattedDate } from 'utilities/helperFunc/formatter';
import { getDateRange } from 'utilities/helperFunc/getDateRange';

type CreateReportModalProps = {
  open: boolean;
  onClose: () => void;
  setCreateReportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormValues = {
  reportType: 'multiple-dealers' | 'all-dealers' | null;
  reportName: string;
  dealers: string[];
  startDate: string;
  endDate: string;
  emails: string[];
  granularity: 'daily' | 'weekly' | 'monthly' | 'yearly' | 'quarterly';
};

const CreateReportModal = ({
  open,
  onClose,
  setCreateReportModalOpen
}: CreateReportModalProps) => {
  const [formComponent, setFormComponent] = useState(0);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState('');

  const { data: distributorData } = useGetDiscoverableProfilesQuery({
    params: {
      organization_type: 'distributor'
    }
  });
  const [createReport, { isLoading }] = useCreateReportMutation({});

  const {
    control,
    trigger,
    formState: { isValid },
    reset,
    setValue,
    watch
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      reportType: null,
      reportName: '',
      dealers: [],
      startDate: '',
      endDate: '',
      granularity: 'daily',
      emails: []
    },
    resolver: yupResolver(
      createReportValidator
    ) as unknown as Resolver<FormValues>
  });

  const reportTypeValue = watch('reportType');
  const values = watch();

  const distributorOptions = useMemo(() => {
    return (
      (distributorData &&
        Object.values(distributorData).map(distributor => ({
          title: distributor.name,
          value: distributor.profile_id
        }))) ||
      []
    );
  }, [distributorData]);

  const onDialogClose = () => {
    onClose();
    reset();
    setFormComponent(0);
  };

  const switchToNextForm = () => {
    setFormComponent(prev => prev + 1);
  };

  const switchToPrevForm = () => {
    setFormComponent(prev => prev - 1);
  };

  const handleDateRange = async (range: string) => {
    if (selectedRange === range) {
      // Uncheck the date range checkbox
      setSelectedRange('');
      setValue('startDate', '');
      setValue('endDate', '');
      await trigger(['startDate', 'endDate']);
    } else {
      const { start, end } = getDateRange(range);
      setSelectedRange(range);
      setValue('startDate', formattedDate(dayjs(start), 'YYYY-MM-DD'));
      setValue('endDate', formattedDate(dayjs(end), 'YYYY-MM-DD'));
      await trigger(['startDate', 'endDate']);
    }
  };

  const submitCreateReportForm = async () => {
    const dealersList = distributorOptions.map(item => item.value);
    return await createReport({
      data: {
        document_name: values.reportName,
        dealers:
          reportTypeValue === 'all-dealers' ? dealersList : values.dealers,
        emails: values.emails
      },
      params: {
        start: values.startDate,
        end: values.endDate,
        granularity: values.granularity
      }
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Creating Report',
          subText:
            'This may take a few minutes. Download it in the reports section once it is ready',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        onDialogClose();
      })
      .catch(() =>
        ShowInfo({
          message: 'Failed to generate a report',
          subText: '',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        })
      );
  };

  const renderFormContent = (formComponent: number) => {
    switch (formComponent) {
      case 1:
        return {
          heading: 'Create Report',
          subHeading: 'Choose what type of report you want to generate.',
          component: (
            <>
              <Box
                sx={{
                  margin: '1rem',
                  mb: '0rem'
                }}
              >
                <FormInput<FormValues>
                  control={control}
                  name="reportName"
                  label="NAME OF REPORT"
                  placeholder="Daily distributor report"
                />

                {reportTypeValue === 'multiple-dealers' && (
                  <FormSelect<FormValues>
                    control={control}
                    name="dealers"
                    label="SELECT DEALER"
                    options={distributorOptions}
                    placeholder="Daily performance of dealers"
                    multiselect
                    renderSelected={(value: string) => (
                      <Box
                        sx={{
                          padding: '0.25rem 0.5rem',
                          backgroundColor: '#F5F6F7',
                          display: 'inline-block',
                          borderRadius: '0.5rem',
                          marginRight: '0.5rem'
                        }}
                        key={value}
                      >
                        <Typography color="#475467" variant="bodyMediumMedium">
                          {_.truncate(value, { length: 15, omission: '...' })}
                        </Typography>
                      </Box>
                    )}
                  />
                )}

                <Stack direction="column">
                  <Typography variant="bodyLargeSemibold" color="#475467">
                    Choose a date range
                  </Typography>

                  <Divider
                    sx={{
                      color: '#F5F6F7',
                      border: '.06rem solid',
                      mt: '.5rem'
                    }}
                  />

                  <Stack direction="row" gap=".75rem" mt="1rem">
                    <FormDateInput<FormValues>
                      control={control}
                      name="startDate"
                      label="FROM"
                      format="YYYY-MM-DD"
                      size="small"
                    />
                    <FormDateInput<FormValues>
                      control={control}
                      name="endDate"
                      label="TO"
                      format="YYYY-MM-DD"
                      size="small"
                    />
                  </Stack>

                  <Stack direction="column" gap=".75rem" mt="1rem">
                    {['Last 30 days', 'Last 7 days', 'Last 14 days'].map(
                      (element, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              backgroundColor: '#FCFCFD',
                              borderRadius: '0.75rem',
                              border: '0.0625rem solid #F2F4F7',
                              width: '100%',
                              display: 'flex',
                              padding: '0.75rem',
                              alignItems: 'center',
                              gap: '0.75rem',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setSelectedRange(element);
                              handleDateRange(element);
                            }}
                          >
                            <Checkbox
                              sx={{
                                width: '1.25rem',
                                height: '1.25rem'
                              }}
                              checked={selectedRange === element}
                              checkedIcon={<RadioChecked />}
                              icon={<RadioUnChecked />}
                            />
                            <Typography
                              variant="bodyMediumMedium"
                              color="#475467"
                            >
                              {element}
                            </Typography>
                          </Box>
                        );
                      }
                    )}
                  </Stack>
                </Stack>
              </Box>
              <Divider
                sx={{
                  color: '#F5F6F7',
                  border: '.06rem solid',
                  mt: '.75rem'
                }}
              />

              <Stack
                direction="column"
                sx={{
                  mb: '0rem',
                  padding: '0.75rem 0.5rem',
                  pb: '0rem',
                  backgroundColor: '#FCFCFD',
                  borderBottom: '.064rem solid #F2F4F7'
                }}
              >
                <Stack direction="column" ml=".5rem">
                  <Typography variant="bodyLargeSemibold" color="#475467">
                    Report View
                  </Typography>
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Choose how you would like to see the data in this report
                  </Typography>
                </Stack>

                <Divider
                  sx={{
                    color: '#F2F4F7',
                    border: '.06rem solid',
                    mt: '.75rem'
                  }}
                />

                {granularityOptions.map((element, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        width: '100%',
                        display: 'flex',
                        padding: '0.75rem',
                        alignItems: 'center',
                        gap: '0.5rem',
                        cursor: 'pointer'
                      }}
                      onClick={() =>
                        setValue('granularity', `${element.value}`)
                      }
                    >
                      <Radio
                        sx={{
                          width: '1.125rem',
                          height: '1.125rem',
                          color: '#D0D5DD'
                        }}
                        checked={values.granularity === element.value}
                      />
                      <Stack direction="column">
                        <Typography
                          variant="bodyMediumSemibold"
                          color="#667085"
                        >
                          {element.title}
                        </Typography>
                        <Typography variant="bodyMediumRegular" color="#667085">
                          {element.subtitle}
                        </Typography>
                      </Stack>
                    </Box>
                  );
                })}
              </Stack>

              <Stack
                direction="column"
                sx={{
                  margin: '1rem',
                  border: '.064rem solid #F5F6F7',
                  borderRadius: '.75rem',
                  padding: '.5rem .75rem',
                  backgroundColor: '#FCFCFD',
                  gap: '.75rem'
                }}
              >
                <Stack
                  direction="row"
                  width="100%"
                  gap=".5rem"
                  sx={{
                    padding: '.5rem 0rem',
                    borderBottom: '.063rem solid #F5F5F5'
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: '6.25rem',
                      border: '.063rem solid #F2F4F7',
                      background: '#F2F4F7',
                      display: 'flex',
                      padding: '0.75rem',
                      width: '2.5rem',
                      height: '2.5rem'
                    }}
                  >
                    <SvgWrapper
                      icon={Send}
                      width="1rem"
                      height="1rem"
                      styleOverrides={{ fill: '#667085' }}
                    />
                  </Box>

                  <Stack direction="column">
                    <Typography variant="bodyMediumSemibold" color="#344054">
                      Share Report
                    </Typography>
                    <Typography variant="bodyMediumMedium" color="#667085">
                      Input emails and press Enter after each to add more
                    </Typography>
                  </Stack>
                </Stack>

                <FormAutocomplete<FormValues>
                  control={control}
                  multiple={true}
                  name="emails"
                  label=""
                  options={[]}
                  size="small"
                  placeholder="Press Enter to add emails"
                />
              </Stack>
            </>
          ),
          disableBtn: !isValid
        };
      default:
        return {
          heading: 'Create Report',
          subHeading: 'Choose what type of report you want to generate.',
          component: (
            <Box
              sx={{
                margin: '1.5rem 1rem',
                mb: '.5rem'
              }}
            >
              <FormRadio<FormValues>
                control={control}
                name="reportType"
                label=""
                radioData={radioData}
              />
            </Box>
          ),
          disableBtn: reportTypeValue === null
        };
    }
  };

  const handleCancelConfirmation = () => {
    setCancelModalOpen(true);
    onClose();
  };

  const { heading, subHeading, component, disableBtn } =
    renderFormContent(formComponent);

  return (
    <>
      {cancelModalOpen && formComponent === 1 && (
        <Dialog
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '23.25rem',
                borderRadius: '0.75rem',
                backgroundColor: '#FFF'
              }
            }
          }}
          open={cancelModalOpen}
          onClose={() => setCancelModalOpen(false)}
        >
          <DialogContent
            sx={{ borderRadius: '.75rem .75rem 0rem 0rem', padding: 0.5 }}
          >
            <Stack
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              sx={{
                flexDirection: 'row',
                background: '#FFF',
                padding: '1.25rem 1rem',
                pb: '1rem'
              }}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  textAlign: 'center'
                }}
              >
                <Typography variant="bodyLargeSemibold" color="#344054">
                  Are you Sure?
                </Typography>
                <Typography variant="bodyMediumRegular" color="#667085">
                  You will not complete creating this report if you go back
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              sx={{
                padding: '1rem',
                pt: '0rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '.75rem'
              }}
            >
              <Button
                color="grey"
                transparent
                size="sm"
                text="Cancel"
                styleOverrides={{
                  borderRadius: '.5rem',
                  padding: '0.75rem',
                  border: '.094rem solid #F2F4F7',
                  color: '#475467',
                  width: '50%'
                }}
                onClick={() => {
                  setCancelModalOpen(false);
                  setCreateReportModalOpen(true);
                }}
              />

              <Button
                color="error"
                outline
                size="sm"
                text="Continue"
                styleOverrides={{
                  borderRadius: '.5rem',
                  padding: '0.75rem',
                  width: '50%'
                }}
                onClick={() => {
                  onDialogClose();
                  setCancelModalOpen(false);
                }}
              />
            </Stack>
          </DialogContent>
        </Dialog>
      )}

      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '29.25rem',
              borderRadius: '0.75rem',
              backgroundColor: '#FFF'
            }
          }
        }}
        open={open}
        onClose={onDialogClose}
      >
        <DialogContent
          sx={{ borderRadius: '.75rem .75rem 0rem 0rem', padding: 0 }}
        >
          <form noValidate>
            <Stack
              sx={{
                flexDirection: 'row',
                background: '#FFF',
                padding: '1.25rem',
                pb: '1rem'
              }}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                width="100%"
              >
                <Stack direction="column" alignItems="flex-start">
                  <Typography variant="h7Bold" color="#475467">
                    {heading}
                  </Typography>
                  <Typography variant="bodyMediumRegular" color="#667085">
                    {subHeading}
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    cursor: 'pointer'
                  }}
                  onClick={
                    formComponent === 1
                      ? handleCancelConfirmation
                      : onDialogClose
                  }
                >
                  <SvgWrapper
                    icon={CloseIcon}
                    width="1.25rem"
                    height="1.25rem"
                    styleOverrides={{ fill: '#475467', stroke: 'none' }}
                  />
                </Box>
              </Box>
            </Stack>

            <Divider
              sx={{
                color: '#F5F6F7',
                border: '.06rem solid'
              }}
            />

            <Stack
              width="100%"
              sx={{
                overflowY: 'auto',
                maxHeight: '35rem'
              }}
            >
              {component}
            </Stack>

            <Divider
              sx={{
                color: '#F5F6F7',
                border: '.06rem solid'
              }}
            />

            <Stack
              direction="row"
              sx={{
                padding: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '.75rem'
              }}
            >
              {formComponent === 1 && (
                <Button
                  color="grey"
                  transparent
                  size="lg"
                  text="Back"
                  styleOverrides={{
                    borderRadius: '.5rem',
                    padding: '0.75rem',
                    border: '.094rem solid #F2F4F7',
                    color: '#475467',
                    width: '50%'
                  }}
                  onClick={() => switchToPrevForm()}
                />
              )}

              <Button
                color="primary"
                size="lg"
                text={formComponent === 1 ? 'Create Report' : 'Next'}
                styleOverrides={{
                  borderRadius: '.5rem',
                  padding: '0.75rem',
                  width: formComponent === 1 ? '50%' : '100%'
                }}
                onClick={() =>
                  formComponent === 1
                    ? submitCreateReportForm()
                    : switchToNextForm()
                }
                disabled={disableBtn}
                loadingIcon={isLoading}
              />
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateReportModal;

const radioData = [
  {
    image: Users,
    heading: 'Create report for all dealers',
    value: 'all-dealers',
    subHeading: ''
  },
  {
    image: User,
    heading: 'Select dealer and create report',
    value: 'multiple-dealers',
    subHeading: ''
  }
];

const granularityOptions: {
  title: string;
  subtitle: string;
  value: 'daily' | 'weekly' | 'monthly' | 'yearly' | 'quarterly';
}[] = [
  {
    title: 'Daily report',
    subtitle: 'See data for each day in range selected',
    value: 'daily'
  },
  {
    title: 'Weekly report',
    subtitle: 'See a summary of data for each week ',
    value: 'weekly'
  },
  {
    title: 'Monthly report',
    subtitle: 'See a summary of data for each month',
    value: 'monthly'
  },
  {
    title: 'Quarterly report',
    subtitle: 'See a summary of data for each quarter',
    value: 'quarterly'
  },
  {
    title: 'Yearly report',
    subtitle: 'See a summary of data for each year',
    value: 'yearly'
  }
];
