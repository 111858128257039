import React from 'react';
import { Paper, Box, Stack, Typography } from '@mui/material';
import { ReactComponent as Truck } from 'assets/svg/truck.svg';
import { ReactComponent as Alarm } from 'assets/svg/alarm-clock.svg';
import { SvgWrapper } from 'components/svg';
import { Chip } from 'components/chip';
import _ from 'lodash';

interface PickupVehicleProps {
  vehiclePhoto: string | null;
  vehicleMake: string;
  vehicleModel: string;
  vehicleColor?: string;
  vehicleLicense: string;
  vehicleMarker: string;
}

const PickupVehicleDetails = ({
  vehiclePhoto,
  vehicleMake,
  vehicleColor,
  vehicleModel,
  vehicleMarker,
  vehicleLicense
}: PickupVehicleProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: '1rem',
        borderRadius: '0.75rem',
        backgroundColor: '#FCFCFD',
        border: '.0625rem solid #F5F6F7',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        gap: '.75rem'
      }}
    >
      <Stack
        width="100%"
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="3.8rem"
        sx={{
          padding: '.75rem .5rem',
          borderBottom: '.0625rem solid #F5F6F7'
        }}
      >
        <Stack alignItems="center" direction="row" gap=".69rem">
          <Box
            sx={{
              borderRadius: '6.25rem',
              padding: '.5rem',
              background: '#ECF2FE',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <SvgWrapper
              icon={Truck}
              width="1rem"
              height="1rem"
              styleOverrides={{ fill: '#2C59B0' }}
            />
          </Box>

          <Stack
            direction="column"
            sx={{
              width: 'auto'
            }}
          >
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              Vehicle
            </Typography>
            <Typography variant="bodyMediumSemibold" color="#344054">
              {_.startCase(vehicleMake)}
            </Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            width: 'auto'
          }}
        >
          <Chip label={vehicleLicense} size="sm" icon />
        </Box>
      </Stack>

      <Stack alignItems="center" direction="row" gap=".75rem">
        {vehiclePhoto ? (
          <Box
            component="img"
            src={vehiclePhoto}
            sx={{
              width: '10.4375rem',
              height: 'auto',
              borderRadius: '0.75rem',
              objectFit: 'contain'
            }}
          />
        ) : null}

        <Box width="100%">
          <Stack
            width="100%"
            display="flex"
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            height="3.8rem"
            sx={{
              borderBottom: '.0625rem solid #F5F6F7',
              padding: '.75rem .5rem'
            }}
          >
            <Stack alignItems="center" direction="row" gap=".69rem">
              <Box
                sx={{
                  borderRadius: '6.25rem',
                  padding: '.5rem',
                  background: '#F5F6F7',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <SvgWrapper
                  icon={Alarm}
                  width="1rem"
                  height="1rem"
                  styleOverrides={{ stroke: '#98A2B3', fill: 'none' }}
                />
              </Box>

              <Stack
                direction="column"
                sx={{
                  width: 'auto'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#98A2B3">
                  Colour
                </Typography>
                <Typography variant="bodyMediumMedium" color="#475467">
                  {_.startCase(vehicleColor)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            height="3.8rem"
            width="100%"
            display="flex"
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            sx={{
              padding: '.75rem .5rem',
              borderBottom: '.0625rem solid #F5F6F7'
            }}
          >
            <Stack alignItems="center" direction="row" gap=".69rem">
              <Box
                sx={{
                  borderRadius: '6.25rem',
                  padding: '.5rem',
                  background: '#F5F6F7',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <SvgWrapper
                  icon={Alarm}
                  width="1rem"
                  height="1rem"
                  styleOverrides={{ stroke: '#98A2B3', fill: 'none' }}
                />
              </Box>

              <Stack
                direction="column"
                sx={{
                  width: 'auto'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#98A2B3">
                  Model
                </Typography>
                <Typography variant="bodyMediumMedium" color="#475467">
                  {_.startCase(vehicleModel)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            height="3.8rem"
            width="100%"
            display="flex"
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            sx={{
              padding: '.75rem .5rem'
            }}
          >
            <Stack alignItems="center" direction="row" gap=".69rem">
              <Box
                sx={{
                  borderRadius: '6.25rem',
                  padding: '.5rem',
                  background: '#F5F6F7',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <SvgWrapper
                  icon={Alarm}
                  width="1rem"
                  height="1rem"
                  styleOverrides={{ stroke: '#98A2B3', fill: 'none' }}
                />
              </Box>

              <Stack
                direction="column"
                sx={{
                  width: 'auto'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#98A2B3">
                  Identifying markers
                </Typography>
                <Typography variant="bodyMediumMedium" color="#475467">
                  {_.startCase(vehicleMarker) || '---'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};

export default PickupVehicleDetails;
