import React, { useState } from 'react';

import { Box, Typography, Stack, Tooltip } from '@mui/material';
import { Button } from 'components/button';
import { Chip } from 'components/chip';
import { Modal } from 'components/modal';
import { SvgWrapper } from 'components/svg';
import ShowInfo from 'components/info/showInfo';

import { ReactComponent as VerticalLineIcon } from 'assets/custom-svg/vertical-line-circle.svg';
import { ReactComponent as DottedVerticalLineIcon } from 'assets/custom-svg/dotted-vertical-line.svg';
import { ReactComponent as ZapFastIcon } from 'assets/svg/zap-fast.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/form-close.svg';
import { ReactComponent as CheckIcon } from 'assets/svg/form-check.svg';
import { ReactComponent as Ellipse } from 'assets/svg/ellipse.svg';
import { ReactComponent as Message } from 'assets/svg/message-chat-cirlce.svg';
import { ReactComponent as InfoWarning } from 'assets/svg/infoWarning.svg';

import { useActionStepMutation } from 'services/api/workflowApi';
import { WorkflowTargetObject } from 'services/enums/workflows';
import { shadowsTheme } from 'themes/shadows';
import { colorTheme } from 'themes/colors';
import { timeDifference } from 'utilities/helperFunc/timeDifference';

type WorkflowViewerStepProps = {
  workflowId: string;
  workflowUseCase?: string;
  title: string;
  description: string;
  stepOrder: number;
  isLast: boolean;
  isActionable: boolean;
  stepOutcome: boolean | null;
  stepReApproved: boolean;
  workflowTargetObject: WorkflowTargetObject;
  workflowTargetObjectId: string;
  invalidateTag: string;
  invalidateTagId: string | number;
  actionedBy: null | {
    email: string;
    name: string;
  };
  actionedOn: string | null;
  ownedByTeam: null | {
    team_id: string;
    name: string;
  };
  ownedByRole: null | {
    description: string;
    role_id: string;
    name: string;
  };
  displayComments: () => void;
  isSelected: boolean;
};

const WorkflowViewerStep: React.FC<WorkflowViewerStepProps> = ({
  workflowId,
  workflowUseCase = 'product_receipt_assignment',
  title,
  description,
  stepOrder,
  isLast,
  isActionable,
  stepOutcome,
  stepReApproved,
  workflowTargetObject,
  workflowTargetObjectId,
  invalidateTag,
  invalidateTagId,
  actionedBy,
  actionedOn,
  ownedByTeam,
  displayComments,
  isSelected,
  ownedByRole
}) => {
  const [stepApprovalModalOpen, setStepApprovalModalOpen] = useState(false);
  const [stepDeclineModalOpen, setStepDeclineModalOpen] = useState(false);
  const [actionStep, { isLoading }] = useActionStepMutation();

  const onClickActionStep = (outcome: boolean, stepOrder: number) => {
    actionStep({
      workflowId: workflowId,
      invalidateTag: invalidateTag,
      invalidateTagId: invalidateTagId,
      data: {
        step_order: stepOrder,
        step_outcome: outcome,
        target_object: workflowTargetObject,
        target_object_id: workflowTargetObjectId,
        current_use_case: workflowUseCase
      }
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: `Step was ${outcome ? 'approved' : 'declined'} successfully.`,
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        if (outcome) {
          setStepApprovalModalOpen(false);
        } else {
          setStepDeclineModalOpen(false);
        }
      })
      .catch(error => {
        ShowInfo({
          message: 'Oops! Something went wrong.',
          subText: `${Object.values(error.data)[0]}`,
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  const isStepActionable = () => {
    return isActionable === true;
  };

  const isStepApproved = () => {
    return stepOutcome === true || stepReApproved === true;
  };

  const isStepDeclined = () => {
    return stepOutcome === false;
  };

  const isStepPending = () => {
    return stepOutcome === null;
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: '0.8',
          cursor: 'pointer'
        }}
        onClick={displayComments}
      >
        <Box
          sx={{
            border: '0.0625rem solid #F2F4F7',
            background: '#FFF',
            display: 'flex',
            width: '24.75rem',
            flexDirection: 'column',
            margin: 'auto',
            borderRadius: '0.75rem',
            boxShadow: isSelected
              ? '0px 0px 0px 4px rgba(144, 182, 255, 0.14)'
              : shadowsTheme.xxSmallShadowSoft,
            mt: '.25rem'
          }}
        >
          <Stack
            padding=".75rem"
            sx={{
              border: `${isStepDeclined() ? '.063rem solid #FFF7F6' : 'none'}`,
              borderRadius: '.75rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    backgroundColor: `${
                      isStepPending()
                        ? colorTheme.grey100
                        : isStepApproved()
                          ? colorTheme.success25
                          : colorTheme.error50
                    }`,
                    borderRadius: '0.438rem',
                    width: '1.75rem',
                    height: '1.75rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '0.25rem'
                  }}
                >
                  <SvgWrapper
                    icon={ZapFastIcon}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{
                      fill: `${
                        isStepPending()
                          ? colorTheme.grey400
                          : isStepApproved()
                            ? colorTheme.success600
                            : colorTheme.error600
                      }`
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: '90%'
                  }}
                >
                  <Typography variant="bodyMediumSemibold" color="#1D2939">
                    {title}
                  </Typography>
                  <Box>
                    <Typography
                      variant="bodyMediumRegular"
                      color="#475467"
                      sx={{
                        letterSpacing: -1
                      }}
                    >
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {isStepPending() ? (
                <Chip label="Pending" size="xs" />
              ) : isStepApproved() ? (
                <Chip label="Approved" size="xs" color="success" />
              ) : (
                <Chip label="Declined" size="xs" color="error" />
              )}
            </Box>
            {isStepDeclined() || isStepApproved() ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  borderTop: 1,
                  borderColor: '#F5F6F7',
                  mt: '1rem'
                }}
              >
                <Box
                  sx={{ display: 'flex', alignItems: 'center', pt: '.75rem' }}
                >
                  <Box
                    sx={{
                      backgroundColor: `${
                        isStepApproved()
                          ? colorTheme.success25
                          : colorTheme.error50
                      }`,
                      borderRadius: '0.438rem',
                      width: '1.75rem',
                      height: '1.75rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '0.25rem'
                    }}
                  >
                    <SvgWrapper
                      icon={isStepApproved() ? CheckIcon : CloseIcon}
                      width="1rem"
                      height="1rem"
                      styleOverrides={{
                        fill: 'none',
                        stroke: `${
                          isStepApproved()
                            ? colorTheme.success600
                            : colorTheme.error600
                        }`
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography variant="bodyMediumMedium" color="#667085">
                      {isStepApproved() ? 'Approved' : 'Declined'} by{' '}
                      {actionedBy?.email || ''}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="captionXSmall" color="#98A2B3">
                        {timeDifference(actionedOn || '')}
                      </Typography>
                      <SvgWrapper
                        icon={Ellipse}
                        width=".25rem"
                        height=".25rem"
                        styleOverrides={{
                          fill: '#D0D5DD',
                          ml: '.25rem'
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  borderTop: 1,
                  borderColor: '#F5F6F7',
                  paddingTop: '1rem',
                  paddingBottom: '.25rem',
                  mt: 1
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Assigned to
                  <Tooltip
                    title={`Sign in as ${ownedByRole?.name || ownedByTeam?.name}`}
                  >
                    <span style={{ color: '#344054' }}>
                      {' '}
                      {ownedByRole?.name || ownedByTeam?.name}
                    </span>
                  </Tooltip>
                </Typography>
              </Box>
            )}
          </Stack>
          {isStepActionable() && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '1.75rem 0.75rem 0.75rem 0.75rem',
                mt: '1rem'
              }}
            >
              {isStepDeclined() ? (
                <>
                  <Button
                    submit
                    color="primary"
                    size="md"
                    text="Resolve & Approve"
                    width="48%"
                    onClick={() => setStepApprovalModalOpen(true)}
                  />
                  <Button
                    color="primary"
                    transparent
                    size="md"
                    text="Leave a comment"
                    width="48%"
                    icon={Message}
                    iconPosition="start"
                    onClick={displayComments}
                  />
                </>
              ) : (
                <>
                  <Button
                    submit
                    color="primary"
                    size="md"
                    text="Approve"
                    width="48%"
                    onClick={() => setStepApprovalModalOpen(true)}
                    disabled={isStepApproved()}
                  />
                  <Button
                    color="error"
                    transparent
                    size="md"
                    text="Decline"
                    width="48%"
                    onClick={() => setStepDeclineModalOpen(true)}
                    disabled={isStepDeclined()}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
        {!isLast && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {isStepActionable() ? (
              <SvgWrapper
                icon={VerticalLineIcon}
                width="1rem"
                height="1rem"
                styleOverrides={{ fill: '#667085' }}
              />
            ) : (
              <SvgWrapper
                icon={DottedVerticalLineIcon}
                width="0.25rem"
                height="2.5rem"
                styleOverrides={{ stroke: '#EAECF0', fill: '#D0D5DD' }}
              />
            )}
          </Box>
        )}
      </Box>
      <Modal
        open={stepApprovalModalOpen}
        onClose={() => setStepApprovalModalOpen(false)}
        text="Step Approval"
        subText={`You are about to approve the ${title} step in your workflow. Do you wish to proceed?`}
        src={InfoWarning}
        onClick={() => {
          onClickActionStep(true, stepOrder);
        }}
        btnProps={{ text: 'Proceed', color: 'primary', loadingIcon: isLoading }}
        modalOverrideProps={{ marginLeft: '20%' }}
        fill="#F9C865"
        svgColor="#FEF8EC"
      />
      <Modal
        open={stepDeclineModalOpen}
        onClose={() => setStepDeclineModalOpen(false)}
        text="Step Decline"
        subText={`You are about to decline the ${title} step in your workflow. Do you wish to proceed?`}
        src={InfoWarning}
        onClick={() => {
          onClickActionStep(false, stepOrder);
        }}
        btnProps={{
          text: 'Decline',
          color: 'error',
          transparent: true,
          loadingIcon: isLoading
        }}
        modalOverrideProps={{ marginLeft: '20%' }}
        fill="#F9C865"
        svgColor="#FEF8EC"
      />
    </>
  );
};
export default WorkflowViewerStep;
