import React from 'react';
import { Stack, Box, Typography, Avatar, IconButton } from '@mui/material';
import logo from 'assets/custom-svg/flux-logo.svg';
import { ReactComponent as Logout } from 'assets/svg/logout.svg';

import { useLogoutUserMutation } from 'services/api/authApi';
import { useAppDispatch, useAppSelector } from 'services/hook';
import { setUserLogout } from 'services/slices/authSlice';
import { useGetUserQuery } from 'services/api/userApi';
import { useGetOrganizationQuery } from 'services/api/organizationApi';
import NavRoutes from './navRoutes';
import { getRoutesForNav } from 'routes';
import _ from 'lodash';

const SideBar: React.FC = () => {
  const authState = useAppSelector(state => state.auth);
  const flags = useAppSelector(state => state.featureFlags);

  const { data: userData } = useGetUserQuery({});
  const { data: organizationData } = useGetOrganizationQuery(
    authState.organizationId
  );
  const [logoutUser] = useLogoutUserMutation();
  const dispatch = useAppDispatch();

  const routes = getRoutesForNav(authState, flags);

  const onLogoutClick = () => {
    logoutUser({})
      .unwrap()
      .then(() => {
        // api.dispatch(api.util.resetApiState()); Not sure we actually need this but will leave for now
        dispatch(setUserLogout());
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <>
      <Box
        sx={{
          height: '-webkit-fill-available',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          margin: {
            md: '.1rem 0.5rem',
            lg: '1.25rem 0.75rem'
          },
          padding: '0 0.75rem'
        }}
      >
        <Stack direction="row" gap=".5rem" alignItems="center">
          <Box
            component="img"
            src={logo}
            sx={{ width: '1.81rem', height: '1.81rem' }}
          />
          <Typography variant="h6Bold" color="#475467">
            Flux
          </Typography>
        </Stack>

        <Stack
          direction="column"
          justifyContent="flex-start"
          gap=".5rem"
          sx={{
            mt: {
              md: '.5rem',
              lg: '2rem'
            }
          }}
        >
          <Typography variant="inputLabel" color="#98A2B3">
            Organization
          </Typography>
          <Stack direction="row" gap=".5rem" alignItems="center">
            <Avatar
              src={organizationData?.logo}
              sx={{
                width: '1.75rem',
                height: '1.75rem'
              }}
            />
            <Typography variant="bodyMediumMedium" color="#475467">
              {organizationData?.name || ''}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            mt: {
              md: '.5rem',
              lg: '1.25rem'
            }
          }}
        >
          <Typography variant="inputLabel" color="#98A2B3">
            Menu
          </Typography>
        </Box>

        <Stack
          display="flex"
          direction="column"
          justifyContent="space-between"
          sx={{
            width: '100%',
            height: 'inherit'
          }}
        >
          <NavRoutes routes={routes} />

          <Stack
            display="flex"
            direction="row"
            alignItems="center"
            width="inherit"
          >
            <Avatar
              src={userData?.user_profile.profile_picture}
              sx={{ width: '2rem', height: '2rem' }}
            />
            <Stack direction="column" sx={{ marginLeft: '0.5rem' }}>
              <Typography variant="bodyMediumSemibold" color="#475467">
                {_.truncate(
                  `${userData?.firstname || ''} ${userData?.lastname || ''}`,
                  { length: 15, omission: '...' }
                )}
              </Typography>
              <Typography variant="bodyMediumRegular" color="#98A2B3">
                {_.truncate(userData?.email || '', {
                  length: 45,
                  omission: '...'
                })}
              </Typography>
            </Stack>

            <IconButton
              sx={{
                width: '3rem',
                height: '3rem',
                padding: '0.75rem',
                marginLeft: 'auto',
                borderRadius: '0.75rem'
              }}
              onClick={() => onLogoutClick()}
            >
              <Logout />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
export default SideBar;
